import Logo from "../../../public/icons/gocore-logo.svg";
import LogoSmall from "../../../public/icons/gocore-logo-small.svg";
import Image from "next/image";
import { FC } from "react";
import Link from "next/link";

interface IGoCoreLogo {
  className?: string;
}

export const GoCoreLogo: FC<IGoCoreLogo> = ({ className }) => {
  return (
    <Link href="/home">
      <div className="hidden md:block">
        <Logo className={className} />
      </div>
      <div className="md:hidden">
        <LogoSmall className={className} />
      </div>
    </Link>
  );
};
