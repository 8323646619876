"use client";
import { GoCoreLogo } from "../logo";
import LolIcon from "../../../public/icons/games/lol.svg";
import DotaIcon from "../../../public/icons/games/dota.svg";
import CSIcon from "../../../public/icons/games/cs.svg";
import TwitterIcon from "../../../public/icons/socials/twitter.svg";
import YoutubeIcon from "../../../public/icons/socials/youtube.svg";
import InstagramIcon from "../../../public/icons/socials/instagram.svg";
import TikTokIcon from "../../../public/icons/socials/tiktok.svg";
import { nanoid } from "nanoid";
import Link from "next/link";
import Image from "next/image";
import { Fragment, ReactNode } from "react";
import { GameFilter, useMyContext } from "../context";

export type Variants = "info" | "success" | "warning" | "error";

const navigationFooter = {
  quicklinks: [
    { name: "Home", href: "/home" },
    { name: "News", href: "/news" },
    { name: "Matches", href: "/matches" },
    { name: "Betting HUB", href: "/hub" },

    { name: "Tips", href: "/tips" },
    { name: "Pick’ems", href: "/pickems" },
    { name: "Contact us", href: "mailto:info@gocore.gg" },
  ],
  games: [
    // {
    //   icon: <LolIcon className="w-6 h-6 text-cyan " />,
    //   href: "#",
    //   name: "LOL",
    // },
    {
      icon: <DotaIcon className="w-6 h-6 text-red" />,
      href: "#",
      name: "DOTA",
    },
    { icon: <CSIcon className="w-6 h-6 text-orange" />, href: "#", name: "CS" },
  ],
  legal: [
    { name: "Privacy policy", href: "/privacy" },
    { name: "Use of Services", href: "/services" },
    { name: "Cookie policy", href: "/cookies" },
  ],
  social: [
    {
      name: "Twitter",
      href: "https://x.com",
      icon: (props: any) => <TwitterIcon />,
    },
    {
      name: "YouTube",
      href: "https://www.youtube.com",
      icon: (props: any) => <YoutubeIcon />,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com",
      icon: (props: any) => <InstagramIcon />,
    },
    {
      name: "TikTok",
      href: "https://www.tiktok.com",
      icon: (props: any) => <TikTokIcon />,
    },
  ],
};

const Footer = () => {
  const { value, setValue } = useMyContext();

  return (
    <footer className="bg-dark-gray w-full" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-[73.75rem] ">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8 p-4 ">
          <div className="space-y-8">
            <GoCoreLogo />

            <div className="flex space-x-6">
              {navigationFooter.social.map((item) => (
                <Link
                  target="_blank"
                  key={nanoid()}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </Link>
              ))}
            </div>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:col-span-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold leading-6 text-blue-gray">
                  Quicklinks
                </h3>
                <div className="grid grid-cols-2 grid-rows-3 mt-4 gap-2">
                  {navigationFooter.quicklinks.map((item) => (
                    <Link
                      key={nanoid()}
                      href={item.href}
                      className="text-sm leading-6 text-gray hover:text-white "
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="grid grid-cols-2 gap-8">
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-blue-gray">
                    Legal
                  </h3>
                  <ul role="list" className="mt-4 space-y-2">
                    {navigationFooter.legal.map((item) => (
                      <li key={nanoid()}>
                        <Link
                          href={item.href}
                          className="text-sm leading-6 text-gray hover:text-white"
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-blue-gray">
                    Games
                  </h3>
                  <ul role="list" className="grid grid-cols-3  mt-2 w-[152px]">
                    {navigationFooter.games.map((item) => (
                      <li key={nanoid()}>
                        <Link
                          onClick={() => setValue(item.name as GameFilter)}
                          href={item.href}
                          className="flex scale-[2] mt-4 justify-center items-center"
                        >
                          {item.icon}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-light-blue p-4 mt-2 text-gray">
          <p className="text-[14px] leading-5 text-gray font-light  max-w-[753px]">
            GOCORE.GG <br />
            Gambling involves risk. Please only gamble with funds that you can
            comfortably afford to lose. Whilst we do our upmost to offer good
            advice and information we cannot be held responsible for any loss
            that maybe be incurred as a result of gambling.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
